<template>
  <a-row v-if="skeletonLoader" :gutter="22" >
    <a-col :xs="24" :sm="12" :md="8" :lg="8" v-for="index in 3" :key="index">
      <ArticleCardSkeleton/>
    </a-col>
  </a-row>
  <a-row v-if="media.length >= 1" :gutter="22">
    <button class="add-media-btn" @click="openAddMediaModal()">
      <i class="icon icon-add_plus"></i>
    </button>
  </a-row>
  <a-row v-if="!skeletonLoader && media.length == 0" :gutter="22" >
    <button @click="openAddMediaModal()" class="add-content-btn"><i class="icon icon-add_plus"></i> Add content</button>
  </a-row>
  <a-row v-else-if="!skeletonLoader" :gutter="[18, 18]">
    <a-col
      v-for="video in media"
      :key="video"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="6"
    >
      <MediaCard 
        :videoUrl="video.link_content ? video.link_content : ''"
        :videoFileName="video.title"
        :id="video.id"
        :for="'Link'"
        :deleteBtn="true"
      />
    </a-col>
  </a-row>
  <UploadMediaModal 
   :visible="visibleUploadDocumentModal"
   :folderId="folderId"
   :modalTitle="'Link'"
   @closeModal="closeAddDocumentModal()"
  />
</template>

<script>
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"
import MediaCard from "./MediaCard.vue";
import UploadMediaModal from "./UploadDocumentModal.vue";

export default {
  data() {
    return {
      visibleUploadDocumentModal: false,
      folderId: "",
    };
  },
  components: {
    ArticleCardSkeleton,
    MediaCard,
    UploadMediaModal
  },
  computed: {
    skeletonLoader() {
      return this.$store.getters["folders/getFolderMediaContentLoader"];
    },
    media() {
      return this.$store.getters["folders/getFolderMediaContent"];
    },
  },
  methods: {
    openAddMediaModal() {
      this.visibleUploadDocumentModal = true;
      this.folderId = this.$route.params.id;
    },
    closeAddDocumentModal(){
      this.visibleUploadDocumentModal = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.add-media-btn {
  background-color: $color-primary;
  height: 4.2rem;
  width: 4.2rem;
  border: none;
  outline: none;
  border-radius: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  .icon {
    color: $color-white;
    font-size: 1.6rem;
  }
}
.add-content-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: $font-primary;
  height: fit-content;
  line-height: normal;
  padding: 0;
  border: none;
  outline: none;
  color: $color-black;
  margin: 5rem auto 0;
  cursor: pointer;
  .icon {
    font-size: 1.4rem;
    margin-right: 1rem;
    margin-bottom: 0.1rem;
  }
}
</style>
